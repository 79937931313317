* {
    scroll-behavior: smooth;
    user-select: none !important;
}

*::-webkit-scrollbar {
    width: 9px;
    /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
    background: rgb(223, 223, 223);
    /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
    background-color: #00ADEF;
    /* color of the scroll thumb */
    border-radius: 100px;
    /* roundness of the scroll thumb */
}

/* #DISPLAYS */
.flex-right {
    display: flex;
    justify-content: end;
}

.display-inline-block {
    display: inline-block !important;
}

.item-center {
    /* depende de la clase 'uk-flex' */
    align-items: center;
}

#contactosFooter760 {
    display: none;
}

#dividerSomosMaker {
    display: none;
}

/* #FUENTES */

@font-face {
    font-family: 'Roboto Condensed Light';
    src: url('./fonts/Roboto/RobotoCondensed-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'OpenSans ExtraBoldItalic';
    src: url('./fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'OpenSans SemiBoldItalic';
    src: url('./fonts/OpenSans/OpenSans-SemiboldItalic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto Black';
    src: url('./fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'OpenSans ExtraBold';
    src: url('./fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'OpenSans Light';
    src: url('./fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}



.font-roboto-condensed-light {
    font-family: 'Roboto Condensed Light' !important;
}

.font-roboto-black {
    font-family: 'Roboto Black' !important;
}

.font-roboto-regular {
    font-family: 'Roboto' !important;
}

.font-opensans-extrabold-italic {
    font-family: 'OpenSans ExtraBoldItalic' !important;
}

.font-opensans-semibold-italic {
    font-family: 'OpenSans SemiBoldItalic' !important;
}

.font-opensans-extrabold {
    font-family: 'OpenSans ExtraBold' !important;
}

.font-opensans-light {
    font-family: 'OpenSans Light' !important;
}


.fs-px-18 {
    font-size: 18px !important;
}

.fs-px-19 {
    font-size: 19px !important;
}

.fs-px-20 {
    font-size: 20px !important;
}

.fs-px-21 {
    font-size: 21px !important;
}

.fs-px-22 {
    font-size: 22px !important;
}

.fs-px-23 {
    font-size: 23px !important;
}

.fs-px-24 {
    font-size: 24px !important;
}

.fs-px-30 {
    font-size: 30px !important;
}

.fs-px-31 {
    font-size: 31px !important;
}

.fs-px-32 {
    font-size: 32px !important;
}

.fs-px-33 {
    font-size: 33px !important;
}

.fs-px-34 {
    font-size: 34px !important;
}

.fs-px-35 {
    font-size: 35px !important;
}

.fs-px-40 {
    font-size: 40px !important;
}

.fs-px-50 {
    font-size: 50px !important;
}

.fs-px-60 {
    font-size: 60px !important;
}

.fs-px-70 {
    font-size: 70px !important;
}

.fs-vw-1 {
    font-size: 1vw !important;
}

.fs-vw-1-5 {
    font-size: 1.5vw !important;
}

.fs-vw-2 {
    font-size: 2vw !important;
}

.fs-vw-3 {
    font-size: 3vw !important;
}

.fs-vw-4 {
    font-size: 4vw !important;
}

/* #BACKGROUNDS  */
.bg-primary {
    background: #00ADEF !important;
}

.bg-color-white {
    background-color: white !important;
}

.color-primary {
    color: #00ADEF !important;
}

.color-white {
    color: #FFFFFF !important;
}


.color-black {
    color: #000000 !important;
}


/* #BOTONES */
.my-button-primary {
    background-color: #00ADEF;
    color: #fff !important;
    border: 2px solid white !important;
    border-radius: 13px !important;
    padding: 0 !important;
}

.my-button-primary:hover {
    background-color: #009ad7;
    color: #fff;
}

.btn-pulse {
    animation: pulse 3s infinite;
}

.btn-services {
    border-radius: 100%;
}

.btn-services:hover {
    border: 2px solid #00ADEF;
}

.btn-pulse-panel {
    animation: pulse-panel 3s infinite;
    border-radius: 100%;
}

.border-none {
    border: none !important;
}

.conoce-mas {
    cursor: pointer;
    width: 130px;
    height: 45px;
}

.btn-conocenos {
    background: transparent !important;
    border: 3px solid #00ADEF;
    border-radius: 10px;
    height: 45px;
    width: 150px;
    color: white;
    font-family: 'OpenSans Light';
    font-size: 25px;
    cursor: pointer;
    transition: background .2s ease-in !important;
    padding: 5px;
}

.btn-conocenos:hover {
    background: #00ADEF !important;
}


.btn-float {
    font-size: 20px;
    /* Cambiar el tamaño de la tipografia */
    color: #ffffff;
    /* Color del texto */
    border-radius: 100%;
    /* Borde del boton */
    background-color: #00ADEF;
    /* Color de fondo */
    padding: 5px;
    /* Relleno del boton */
    position: fixed;
    border: #ffffff 2px solid;
    bottom: 30px;
    right: 30px;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.btn-float:hover {
    background: #008ec7;
    /* Color de fondo al pasar el cursor */
    background-color: #008ec7;
    border: #008ec7 2px solid;
    transform: translateY(-7px);
}

.btn-slide-menu {
    margin-top: 10px;
}

.btn-close-navmenu {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 100% !important;
    border: 2px solid white !important;
    padding: 1px !important;
}

/* #MARGENES - PADDING */
.margin-padding-0 {
    margin: 0 !important;
    padding: 0 !important;
}

.margin-0 {
    margin: 0 !important;
}

.padding-0 {
    padding: 0 !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-45px {
    padding: 45px !important;
}

.hr-list-divider {

    border: 1px solid #02acf0;
}

/* #BORDES */
.remove-border-bottom {
    border-bottom: 0px solid transparent !important;
}


/* #ANIMACIONES */

.slider {
    background: white;
    height: 150px;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slider .slide-track {
    list-style: none;
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(200px * 58);
}

.slider .slide {
    width: 200px;
}

/* #IMAGEN */
.img-contain {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover !important;
}

.nav-menu {
    margin-top: 5px;
    border-bottom: 2px solid white;
    padding-bottom: 20px;
}

/* Para cada hijo de nav-menu */
.nav-menu>a {
    color: white !important;
    margin: 0 !important;
    border-radius: 10px;
    padding: 20px;
    transition: background .2s ease-in !important;
}

.nav-menu>a:hover {
    text-decoration: none;
    background: #ffffff67;
}

/* #ELEMENTOS  */

.navmenu-slide {
    display: none !important;
}

.my-offcanvas {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
}

.cur-pointer {
    cursor: pointer !important;
}

#body-canvas {
    background: #00ADEF;
}

.uk--dotnav-circle {
    /* Tamaño */
    height: 14px !important;
    width: 14px !important;
}

#uk-dotnav-slide-novedades .uk-active>*{
    background-color: #00ADEF;
    border-color: #00ADEF;
}

#uk-dotnav-slide-novedades *>*{
    background-color: #00b7ff59;
    border-color: #00b7ff;
}

footer {
    border-bottom: 60px solid #00ADEF;
}

header {
    user-select: none;
}


@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #FFF;
    }

    70% {
        box-shadow: 0 0 0 10px transparent;
    }

    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

@keyframes pulse-panel {
    0% {
        -moz-box-shadow: 0 0 0 0 #00ADEF;
        box-shadow: 0 0 0 0 #00ADEF;
    }

    70% {
        -moz-box-shadow: 0 0 0 10px transparent;
        box-shadow: 0 0 0 10px transparent;
    }

    100% {
        -moz-box-shadow: 0 0 0 0 transparent;
        box-shadow: 0 0 0 0 transparent;
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-200px * 29));
        /* -200px (hace referencia al tamaño de .slider y .slide * (n elementos en el slider)*/
    }
}


/* #MEDIA */

@media(max-width: 450px) {
    .font-card {
        font-size: 20px !important;
    }
}

@media(max-width: 370px) {
    .font-card {
        font-size: 16px !important;
    }
}

@media(max-width: 1250px) {
    .font-card {
        font-size: 20px !important;
    }
}

@media (max-width: 760px) {
    #contactosFooter760 {
        display: block !important;
    }

    #contactosFooter {
        display: none !important;
    }

    .btn-conocenos {
        margin-bottom: 10px;
    }

    #textDividerBtn {
        font-size: 28px!important;
    }
}

@media (max-width: 1024px) {
    .fs-vw-4 {
        font-size: 3vw !important;
    }
}

@media(max-width: 960px) {
    #dividerSomosMaker {
        display: block !important;
    }

    #mySlider {
        height: 5000px !important;
    }

    /* Trabajo sobre las fuentes del slide principal */
    .fs-vw-1-5 {
        display: none;
        font-size: 4.5vw !important;
    }

    .fs-vw-4 {
        font-size: 8vw !important;
    }

    .fs-vw-2 {
        font-size: 5vw !important;
    }

    #navmenu-main {
        display: none !important;
    }

    .navmenu-slide {
        display: block !important;
    }
}

@media(max-width: 500px) {
    .fs-vw-2 {
        font-size: 7vw !important;
    }

}

@media (max-width: 1199px) {

    /* Por algun motivo cuando pasa esta resoucion, se achica el navmenu header y por eso le damos padding */
    #navmenu-main {
        padding: 40px !important;
    }
}

/* .chevron-novedades:nth-child(1){
    transform: rotate(180deg)!important;
} */

.item-center{
    align-items: center!important;
}

#newsSimpleComponent{
    display: none;
}

@media( max-width: 959px ){
    #newsSimpleComponent{
        display: block;
    }
    #newsComponent{
        display: none!important;
    }
}

#dotnav-novedades{
    top: 670px;
}

@media(max-width: 1130px){
    #dotnav-novedades{
        top: 600px!important;
    }
}